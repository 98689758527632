<template>
  <v-container
    v-if="Object.keys(user).length > 0"
  >
    <div
      class="text-center"
    >
      <h1
        v-translate="{ firstName: user.firstName }"
        translate-context="Page title (registration steps)"
      >
        Hello %{ firstName }!
      </h1>

      <p
        v-translate
        translate-context="Page content (registration steps)"
        class="backgroundAccent--text text--darken-3 mt-1"
      >
        Lets deal with just a few things and you can get to work!
      </p>
    </div>

    <v-card
      max-width="500px"
      class="mx-auto"
    >
      <v-stepper
        v-model="step"
        vertical
      >
        <v-stepper-step
          :complete="step > 1"
          step="1"
        >
          <span
            v-translate
            translate-context="Page content (registration steps) step title"
          >
            Accept use of personal data
          </span>
        </v-stepper-step>

        <v-stepper-content
          step="1"
        >
          <v-card>
            <v-card-text class="pa-0">
              <div
                v-for="(detail, i) in userDetails"
                :key="i"
                cols="12"
                class="font-weight-medium body-2 font-italic mb-1"
              >
                {{ detail.value || '-' }}
              </div>

              <v-divider
                class="my-5"
              />

              <div>
                <p
                  v-translate
                  translate-context="Page content (registration steps)"
                >
                  Personal data will be handled according to Mestamaster's privacy statement.
                </p>

                <a
                  v-translate
                  translate-context="Page content (registration steps) link"
                  target="_blank"
                  href="https://mestamaster.fi/tietosuojaseloste"
                >
                  Open privacy statement
                </a>

                <v-icon
                  class="ml-1"
                  x-small
                  style="vertical-align: text-top;"
                >
                  open_in_new
                </v-icon>

                <v-checkbox
                  v-model="tmpUser.gdprConsent"
                  v-validate="'required:true'"
                  :error-messages="errors.collect('gdprConsent', gdprValidationScope)"
                  :name="`${gdprValidationScope}.gdprConsent`"
                >
                  <template v-slot:label>
                    <span
                      v-translate
                      translate-context="Page content (registration steps)"
                      class="caption"
                    >
                      I accept the handling of personal data
                    </span>
                  </template>
                </v-checkbox>
              </div>
            </v-card-text>
            <v-card-actions
              class="flex-column align-end pb-0"
            >
              <v-btn
                :disabled="!tmpUser.gdprConsent || submitting"
                :loading="submitting"
                color="success"
                block
                large
                @click="onSubmitGdpr"
              >
                <span
                  v-translate
                  translate-context="Page content (registration steps) button"
                >
                  Continue
                </span>
                <v-icon
                  right
                  small
                >
                  thumb_up
                </v-icon>
              </v-btn>

              <v-btn
                :disabled="submitting"
                text
                x-small
                color="error"
                class="mt-5 ml-0"
                @click="openRequestDialog"
              >
                <span
                  v-translate
                  translate-context="Page content (registration steps) button"
                >
                  I want to delete my data
                </span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-step
          :complete="step > 2"
          step="2"
        >
          <span
            v-translate
            translate-context="Page content (registration steps) step title"
          >
            Set a password
          </span>
        </v-stepper-step>

        <v-stepper-content
          step="2"
        >
          <v-card>
            <v-form
              :data-vv-scope="passwordValidationScope"
              @submit.prevent="onSubmitPassword"
            >
              <v-card-text class="pt-2">
                <p
                  v-translate
                  translate-context="Page content (registration steps)"
                  class="caption backgroundAccent--text text--darken-3"
                >
                  A password has to be at least
                  four (4) characters long.
                </p>

                <v-text-field
                  ref="password"
                  v-model="tmpUser.password"
                  v-validate="{
                    required: true,
                    min: 4,
                  }"
                  :error-messages="errors.collect('password', passwordValidationScope)"
                  :name="`${passwordValidationScope}.password`"
                  data-vv-validate-on="blur"
                  :label="passwordPlaceholder"
                  type="password"
                  outlined
                />

                <v-text-field
                  v-model="tmpUser.passwordConfirmation"
                  v-validate="{
                    required: true,
                    confirmed: 'password',
                    min: 4,
                  }"
                  :error-messages="errors.collect('passwordConfirmation', passwordValidationScope)"
                  :name="`${passwordValidationScope}.passwordConfirmation`"
                  data-vv-validate-on="blur"
                  :label="passwordConfirmationPlaceholder"
                  type="password"
                  outlined
                />
              </v-card-text>
              <v-card-actions>
                <v-btn
                  :disabled="submitting"
                  :loading="submitting"
                  block
                  large
                  color="success"
                  type="submit"
                >
                  <span
                    v-translate
                    translate-context="Page content (registration steps) button"
                  >
                    Save
                  </span>
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-stepper-content>

        <v-stepper-step
          :complete="step > 3"
          step="3"
        >
          <span
            v-translate
            translate-context="Page content (registration steps) step title"
          >
            Activate push notifications
          </span>
        </v-stepper-step>

        <v-stepper-content
          step="3"
        >
          <v-card
            v-if="hasActiveSubscription"
          >
            <v-card-text class="pt-2">
              <p
                v-translate
                translate-context="Page content (registration steps)"
                class="caption backgroundAccent--text text--darken-3"
              >
                Push notifications are already enabled on this device.
                You can continue to the next step.
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                block
                large
                color="success"
                @click="nextStep"
              >
                <span
                  v-translate
                  translate-context="Page content (registration steps) button"
                >
                  Continue
                </span>
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-card
            v-else-if="permission && permission === 'denied'"
          >
            <v-card-text class="pt-2">
              <p
                v-translate
                translate-context="Page content (registration steps)"
                class="body-2 backgroundAccent--text text--darken-3"
              >
                You have blocked notifications.
              </p>

              <p class="caption backgroundAccent--text text--darken-3 mb-0">
                <span
                  v-translate
                  translate-context="Page content (registration steps) enabling notifications [1/2]"
                >
                  Notifications can be enabled in your web browser's settings.
                  Ask your foreman for advice or
                </span>
                <span
                  v-translate
                  translate-context="Page content (registration steps) enabling notifications [2/2]"
                  class="blue--text cursor-pointer"
                  @click="nextStep"
                >
                  continue here</span>.
              </p>
            </v-card-text>
          </v-card>
          

          <v-card
            v-else-if="supportsWebPush"
          >
            <v-card-text class="pt-2">
              <p
                v-translate
                translate-context="Page content (registration steps)"
                class="body-2 backgroundAccent--text text--darken-3"
              >
                Push notifications can give you realtime information about your tasks.
              </p>

              <p
                class="caption backgroundAccent--text text--darken-3 mb-0"
              >
                <span
                  v-translate
                  translate-context="Page content (registration steps) not using notifications [1/2]"
                >
                  If you don't want to enable notifications for some reason,
                  please discuss with your foreman about alternative solutions.
                  You can skip this step
                </span>
                <span
                  v-translate
                  translate-context="Page content (registration steps) not using notifications [2/2]"
                  class="blue--text cursor-pointer"
                  @click="nextStep"
                >
                  here</span>.
              </p>
            </v-card-text>
            <v-card-actions>
              <v-layout
                row
                wrap
              >
                <v-flex
                  xs12
                >
                  <v-btn
                    :disabled="$wait.is('subscribing to push notifications')"
                    :loading="$wait.is('subscribing to push notifications')"
                    block
                    large
                    color="success"
                    @click="registerToPushNotifications"
                  >
                    <span
                      v-translate
                      translate-context="Page content (registration steps) button"
                    >
                      Activate
                    </span>
                    <v-icon
                      small
                      right
                    >
                      alarm_on
                    </v-icon>
                  </v-btn>
                </v-flex>

                <v-flex
                  xs12
                >
                  <p
                    v-translate
                    translate-context="Page content (registration steps)"
                    class="caption backgroundAccent--text text--darken-3 text-center mt-3 mb-0"
                  >
                    Your browser will still ask for your permission to send notifications
                  </p>
                </v-flex>
              </v-layout>
            </v-card-actions>
          </v-card>

          <v-card v-else>
            <v-card-text class="pt-2">
              <p
                v-translate
                translate-context="Page content (registration steps)"
                class="caption backgroundAccent--text text--darken-3"
              >
                Unfortunately your device or browser does not support push notifications.
                You can continue to the next step.
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                block
                large
                color="success"
                @click="nextStep"
              >
                <span
                  v-translate
                  translate-context="Page content (registration steps) button"
                >
                  Continue
                </span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-step
          :complete="step > 4"
          step="4"
        >
          <span
            v-translate
            translate-context="Page content (registration steps) step title"
          >
            Install shortcut
          </span>
        </v-stepper-step>

        <v-stepper-content
          step="4"
        >
          <v-card
            v-if="installAppPrompt"
          >
            <v-card-text class="pt-2">
              <p
                v-translate
                translate-context="Page content (registration steps)"
                class="caption backgroundAccent--text text--darken-3 mb-0"
              >
                A shortcut is like a regular application
                which makes accessing Mestamaster quick and easy.
              </p>
            </v-card-text>
            <v-card-actions
              class="d-flex flex-column"
            >
              <v-btn
                :disabled="appInstalling"
                :loading="appInstalling"
                block
                large
                color="success"
                @click="addToHomescreen"
              >
                <span
                  v-translate
                  translate-context="Page content (registration steps) button"
                >
                  Install
                </span>
                <v-icon
                  small
                  right
                >
                  add_to_home_screen
                </v-icon>
              </v-btn>

              <v-btn
                color="primary"
                block
                large
                text
                class="mt-2"
                @click="nextStep"
              >
                <span
                  v-translate
                  translate-context="Page content (registration steps) button"
                >
                  Skip
                </span>
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-card
            v-else
          >
            <v-card-text class="pt-2">
              <p
                v-translate
                translate-context="Page content (registration steps)"
                class="caption backgroundAccent--text text--darken-3"
              >
                Unfortunately your device or browser does not support shortcuts.
                You can continue to the next step.
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                block
                large
                color="success"
                @click="nextStep"
              >
                <span
                  v-translate
                  translate-context="Page content (registration steps) button"
                >
                  Continue
                </span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-step
          :complete="step > 5"
          step="5"
        >
          <span
            v-translate
            translate-context="Page content (registration steps) step title"
          >
            Finished
          </span>
        </v-stepper-step>

        <v-stepper-content
          step="5"
        >
          <v-card>
            <v-card-text class="pt-2">
              <p
                v-translate
                translate-context="Page content (registration steps)"
                class="caption backgroundAccent--text text--darken-3 mb-2"
              >
                Everything is ready. Thank you for your patience!
              </p>
              <p
                v-translate="{ firstName: user.firstName }"
                translate-context="Page content (registration steps)"
                class="subheading font-weight-bold backgroundAccent--text text--darken-3"
              >
                Have a nice day at work %{ firstName }!
              </p>
            </v-card-text>
            <v-card-actions>
              <v-layout
                row
                wrap
              >
                <v-flex
                  xs12
                >
                  <v-btn
                    block
                    large
                    color="success"
                    @click="onSubmitLast"
                  >
                    <span
                      v-translate
                      translate-context="Page content (registration steps) button"
                    >
                      Open projects
                    </span>
                    <v-icon
                      small
                      right
                    >
                      touch_app
                    </v-icon>
                  </v-btn>
                </v-flex>

                <v-flex
                  xs12
                >
                  <p
                    v-translate
                    translate-context="Page content (registration steps)"
                    class="caption backgroundAccent--text text--darken-3 text-center mt-3 mb-0"
                  >
                    You will be automatically signed in
                  </p>
                </v-flex>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper>
    </v-card>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    $_veeValidate: { validator: 'new' },

    data: () => ({
      step: 1,
      gdprValidationScope: 'gdpr',
      passwordValidationScope: 'user',
      tmpUser: {
        password: '',
        passwordConfirmation: '',
        gdprConsent: false,
      },
      submitting: false,
    }),

    computed: {
      ...mapGetters({
        user: 'registration/user/user',
        installAppPrompt: 'ahs/installAppPrompt',
        appInstalling: 'ahs/appInstalling',
        appInstalled: 'ahs/appInstalled',
        supportsWebPush: 'sw/supportsWebPush',
        hasActiveSubscription: 'sw/hasActiveSubscription',
        permission: 'sw/permission',
      }),

      userDetails() {
        const labels = {
          fullName: 'Nimi',
          email: 'Sähköposti',
          phoneNumber: 'Puhelinnumero',
          humanReadableRole: 'Rooli',
        };

        return Object.entries(labels).map((entry) => {
          const [key, label] = entry;

          return {
            label,
            value: this.user[key],
          };
        });
      },

      passwordPlaceholder() {
        return this.$pgettext('Text input placeholder', 'Password');
      },

      passwordConfirmationPlaceholder() {
        return this.$pgettext('Text input placeholder', 'Confirm password');
      },
    },

    methods: {
      ...mapActions({
        updateUser: 'registration/user/updateUser',
        loginUser: 'registration/user/loginUser',
        promptAppInstall: 'ahs/promptAppInstall',
        subscribeToPushNotifications: 'sw/subscribeToPushNotifications',
        validatePushSubscription: 'sw/validatePushSubscription',
        openDialog: 'dialog/openDialog',
      }),

      async onSubmitGdpr() {
        const valid = await this.$validator.validateAll(this.gdprValidationScope);

        if (!valid) return;

        this.submitting = true;

        try {
          await this.updateUser({
            payload: {
              user: {
                gdprConsent: this.tmpUser.gdprConsent,
              },
            },
          });

          this.nextStep();
        } catch (e) {} // eslint-disable-line

        this.submitting = false;
      },

      async onSubmitPassword() {
        const valid = await this.$validator.validateAll(this.passwordValidationScope);

        if (!valid) return;

        this.submitting = true;

        try {
          await this.updateUser({
            payload: {
              user: {
                password: this.tmpUser.password,
                passwordConfirmation: this.tmpUser.passwordConfirmation,
              },
            },
          });

          this.nextStep();
        } catch (e) {} // eslint-disable-line

        this.submitting = false;
      },

      async registerToPushNotifications() {
        const success = await this.subscribeToPushNotifications();
        if (!success) return;
        this.nextStep();
      },

      async addToHomescreen() {
        await this.promptAppInstall();

        this.nextStep();
      },

      async onSubmitLast() {
        this.submitting = true;

        try {
          await this.loginUser();

          this.$router.replace({
            name: 'projects-list',
          });
        } catch (e) {} // eslint-disable-line

        this.submitting = false;
      },

      nextStep() {
        this.step += 1;
      },

      openRequestDialog() {
        this.openDialog({
          dialogComponent: 'm-request-information-destroy-dialog',
        });
      },
    },
  };
</script>
